import React from 'react';
import { Layers } from '../components/backgrounds';
import {
  SEO, Layout, RWLogo, Nav, Card, Grid,
} from '../components';
import '../index.css';
import styled from 'styled-components';

const delay = 0;
const ServicesPage = () => (
  <Layout>
    <SEO title="Services" keywords={['rick', 'livernois', 'developer']} />
    <Layers />
    <Nav show page="services" />
    <Grid>
      <Card color="rgba(63, 59, 55, 0.93)" fontColor="#CCC" title="Design" delay={delay}>
        <Smaller>
          A professional design system will provide a baseline for content creation and human
          interface design for your brand. I prioritize user experience and ensure your brand can
          reach mobile customers.
        </Smaller>
      </Card>
      <Card color="rgba(249, 219, 25, 0.93)" fontColor="#222" title="Strategy" delay={delay + 600}>
        <Smaller>
          Search Engine Optimization is only part of a continuous marketing process. I leverage
          analytics and market research to find the most efficient paths to maximize page rank,
          content exposure, and customer conversions.
        </Smaller>
      </Card>
      <Card
        color="rgba(129, 77, 22, 0.93)"
        fontColor="#CCC"
        title="Development"
        delay={delay + 400}
      >
        <Smaller>
          Bring me your ideas, art assets, or content and I will write the code to realize your
          vision. If you have a cloud backend in place, I can construct a fresh interface. If you
          are starting from scratch, I will engineer a scalable solution to power your business.
        </Smaller>
      </Card>
      <Card color="rgba(202, 206, 179, 0.93)" fontColor="#222" title="Security" delay={delay + 200}>
        <Smaller>
          I test and audit my products to ensure your business platforms are rock solid. You cannot
          afford to treat hardening and accountability as afterthoughts. As an experienced network
          defense professional, I implement policy and safeguards from the beginning.
        </Smaller>
      </Card>
    </Grid>
    <RWLogo />
  </Layout>
);

const Smaller = styled.div`
  font-size: 3.8vmin;
  margin: 0 1vmin;
  @media only screen and (min-width: 800px) {
    font-size: 2.1vmin;
  }
`;

export default ServicesPage;
